<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>关键词</el-breadcrumb-item>
                    <el-breadcrumb-item>列表</el-breadcrumb-item>
                    <el-breadcrumb-item>添加</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_content">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">关键词</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model.trim="form.key_word" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">分类</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-select v-model="form.cate_name" clearable placeholder="请选择">
                                    <el-option v-for="item in classifyOptions" :key="item" :value="item"> </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item v-show="form.cate_name == '热搜词'">
                        <template slot="label">
                            <div class="form_label">类型</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-select v-model="form.type" clearable placeholder="请选择">
                                    <el-option
                                        v-for="item in typeOptions"
                                        :key="item.value"
                                        :value="item.value"
                                        :label="item.label"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item v-if="form.type == '共享' || form.type == '底部'">
                        <template slot="label">
                            <div class="form_label">排序</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-input-number v-model="form.sort" :step="1" :min="1"></el-input-number>
                            </div>
                        </template>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 悬浮按钮 -->
            <div class="page_fab">
                <VyIcon name="确认" scale="4" @click.native="updataInfo"></VyIcon>
                <VyIcon name="取消" scale="4" @click.native="onModuleClose"></VyIcon>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { VyIcon },
    data() {
        return {
            form: {},

            // 类型
            typeOptions: [
                {
                    label: "共享",
                    value: 1,
                },
                {
                    label: "搜索",
                    value: 2,
                },
                {
                    label: "底部",
                    value: 3,
                },
            ],

            // 分类名
            classifyOptions: ["客户案例", "热搜词"],
        };
    },
    activated() {
        this.form = {
            cate_name: "客户案例",
        };
        // 重置表单数据
    },
    mounted() {},
    computed: {},
    methods: {
        // 完成修改
        updataInfo() {
            if (this.form.key_word === undefined || !this.form.key_word.replaceAll(" ", ""))
                return this.$message.error("不能为空！");
            axios
                .post(
                    "/api/Key_word/editOrAdd",
                    qs.stringify({
                        ...this.form,
                    })
                )
                .then(res => {
                    if (res.data.code == 500) {
                        this.$message.error(res.data.msg);
                    } else {
                        this.$message.success(res.data.msg);
                        this.onModuleClose();
                    }
                });
        },

        // 前往主页
        onModuleClose() {
            this.$store.commit("chooseModule/onChoose", "index");
        },
    },
};
</script>
<style scoped lang="less">
/deep/ .el-form-item__content {
    line-height: 0 !important;
}

.page {
    .page_content {
        width: 90%;
        padding: 40px;

        .form_label {
            width: 80px;
            height: 40px;
            line-height: 43px;
            text-align: center;
            color: white;
            font-size: 16px;
            border: 0;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(to right, #da8cff, #9a55ff);
        }

        .form_value {
            .form_input {
                height: 40px;
                width: 100%;
                padding-left: 15px;
                border: 1px solid #ebedf2;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 0 13px 13px 0;

                &:focus {
                    outline: 1px solid skyblue;
                }
            }
        }
    }
}
</style>
